import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInlineForm = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '5px'};
  background-color: ${({ backgroundColor, theme }) =>
    theme.color[backgroundColor]};
`;

const InlineForm = ({
  children,
  onSubmit,
  backgroundColor,
  borderRadius,
  margin,
  padding,
}) => (
  <StyledInlineForm
    onSubmit={onSubmit}
    borderRadius={borderRadius}
    backgroundColor={backgroundColor}
    margin={margin}
    padding={padding}
  >
    {children}
  </StyledInlineForm>
);

InlineForm.propTypes = {
  children: PropTypes.array,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  onSubmit: PropTypes.func,
};
export default InlineForm;
