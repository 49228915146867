import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInputField = styled.input`
  height: 41px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  border: none;
  span {
    color: red;
    font-size: 16px;
    line-height: 18px;
  }
  input {
    margin-top: 12px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: black;
    height: 49px;
    width: 100%;
    border: 1px solid #d6dce1;
  }
  input,
  input::-webkit-input-placeholder {
    color: blue;
    font-size: 16px;
    line-height: 18px;
  }
  input:focus {
    border: 1px solid pink;
  }
`;

const InputField = ({
  children,
  onChange,
  placeholder,
  disabled,
  name,
  onBlur,
  value,
  borderRadius,
}) => (
  <StyledInputField
    onChange={onChange}
    value={value}
    name={name}
    onBlur={onBlur}
    disabled={disabled}
    placeholder={placeholder}
    borderRadius={borderRadius}
  >
    {children}
  </StyledInputField>
);

InputField.propTypes = {
  children: PropTypes.string,
  borderRadius: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
export default InputField;
