import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { isValidURL } from '@global/utils/utils';

const LinkOrA = ({ link, title, className, children }) => {
  const url = isValidURL(link) || link.includes('mailto');
  if (url) {
    return (
      <a href={link} target="_blank" rel="noreferrer" className={className}>
        {children || title}
      </a>
    );
  }
  if (!url) {
    return (
      <Link className={className} to={link}>
        {children || title}
      </Link>
    );
  }
};

LinkOrA.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default LinkOrA;
