import React from 'react';
import styled from 'styled-components';

const HandleSize = size => {
  switch (size) {
    case 'large':
      return '450px';
    case 'medium':
      return '300px';
    default:
      return '180px';
  }
};

const StyledCircleCard = styled.div`
  width: ${({ size }) => HandleSize(size)};
  height: ${({ size }) => HandleSize(size)};
  background: ${({ theme }) => theme.color.primaryBackgroundMedium};
  box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.15);
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledCircle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  svg {
    width: 50%;
    margin: 0 auto;
  }
`;
const CircleCard = ({ children, size, className }) => (
  <StyledCircleCard
    className={className ? `${className} circle_card` : 'circle_card'}
    size={size}
  >
    <StyledCircle>{children}</StyledCircle>
  </StyledCircleCard>
);

export default CircleCard;
