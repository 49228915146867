import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledDivider = styled.hr`
  border: 1px solid ${({ theme }) => theme.color.lightBackground};
  margin: ${({ margin }) => margin || '0'};
  width: 100%;
`;

const Divider = ({ margin }) => <StyledDivider margin={margin} />;

Divider.propTypes = {
  margin: PropTypes.string,
};

export default StyledDivider;
