import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { widthFrom, widthUntil } from '@theme/theme';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin || '0 auto'};
  flex-shrink: 0;
  padding: 0 5%;
  box-sizing: border-box;

  ${widthFrom.TABLET`
    padding: 0 3%;
  `}

  ${widthFrom.SCREEN`
    max-width: 1280px;
  `}

  ${widthUntil.MOBILE`
      flex-direction: ${({ mobileIsColumnReverse }) =>
        mobileIsColumnReverse ? 'column-reverse' : 'column'};
  `}
`;

const Container = ({
  children,
  height,
  isRow,
  margin,
  mobileIsColumn,
  mobileIsColumnReverse,
}) => (
  <StyledContainer
    height={height}
    isRow={isRow}
    margin={margin}
    mobileIsColumn={mobileIsColumn}
    mobileIsColumnReverse={mobileIsColumnReverse}
  >
    {children}
  </StyledContainer>
);

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  height: PropTypes.number,
  margin: PropTypes.string,
  isRow: PropTypes.bool,
  mobileIsColumn: PropTypes.bool,
  mobileIsColumnReverse: PropTypes.bool,
};
export default Container;
