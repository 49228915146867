import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  width: 100%;
  height: auto;
  background: ${({ theme, backgroundColor }) => theme.color[backgroundColor]};
  box-shadow: 6px 6px 18px -12px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  transition: all 350ms ease-in-out;
  &:hover {
    background: ${({ theme, hoverColor }) =>
      hoverColor && theme.color[hoverColor]};
    cursor: pointer;
    box-shadow: ${({ hoverColor }) =>
      hoverColor && '5px 5px 7px 0px rgba(0,0,0,0.25)'};
  }
`;
const Card = ({
  children,
  backgroundColor,
  hoverColor,
  marginBottom = '5vh',
}) => (
  <StyledCard
    backgroundColor={backgroundColor}
    hoverColor={hoverColor}
    marginBottom={marginBottom}
    className="card"
  >
    {children}
  </StyledCard>
);

export default Card;
