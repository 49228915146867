import React from 'react';
import styled from 'styled-components';
import { widthFrom } from '@theme/theme';

const StyledParagraph = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${widthFrom.TABLET_LARGE`
    width: 80%;
  `}
  .icon {
    margin: 2vh 0 0.75vh;
  }
`;
const Paragraph = ({ children }) => (
  <StyledParagraph className="paragraph">{children}</StyledParagraph>
);

export default Paragraph;
