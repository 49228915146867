import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Icons Set
import iconSet from '@assets/icons/iconSet.json';

const StyledContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  svg {
    fill: ${({ theme, color }) => theme.color[color] || theme.color.white};
    width: 100%;
    height: auto;
  }

  &:hover {
    svg {
      fill: ${({ theme, hoverColor }) => hoverColor && theme.color[hoverColor]};
    }
  }
`;

const Icon = ({ title, size = '35', color, extClass, hoverColor }) => {
  const icon = iconSet[title];
  return (
    <StyledContainer
      size={size}
      color={color}
      hoverColor={hoverColor}
      className={`icon ${extClass}`}
    >
      <svg viewBox="0 0 30 30" className={title}>
        <path fill={color} d={icon} />
      </svg>
    </StyledContainer>
  );
};

Icon.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  extClass: PropTypes.string,
  hoverColor: PropTypes.string,
};

export default Icon;
