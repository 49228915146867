import styled from 'styled-components';

const StyledTopNavContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  height: 80px;
`;

export default StyledTopNavContainer;
