import React from 'react';
import PropTypes from 'prop-types';

const FooterIcon = ({ title, link, icon }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    className="mr-6 text-gray-400 hover:text-gray-500"
  >
    <span className="sr-only">{title}</span>
    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
      <path fillRule="evenodd" d={icon} clipRule="evenodd" />
    </svg>
  </a>
);

FooterIcon.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default FooterIcon;
