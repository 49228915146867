import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  background: ${({ primary, theme }) =>
    primary ? theme.color.primaryColor : 'white'};
  color: ${({ primary, theme }) =>
    primary ? 'white' : theme.color.primaryColor};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin: ${({ margin }) => margin || '1em'};
  width: ${({ width }) => width || 'unset'};
  padding: 0.25em 1em;
  border: 1px solid ${({ theme }) => theme.color.primaryColor};
  border-radius: 3px;
  transition: all 350ms ease-in-out;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(69, 214, 139, 0.2);
  height: 41px;
  &:hover {
    background: ${({ theme }) => theme.color.white};
    cursor: pointer;
    color: ${({ theme }) => theme.color.primaryColor};
    box-shadow: '5px 5px 7px 0px rgba(0,0,0,0.25)'};
  }
`;

const Button = ({
  children,
  primary,
  onClick,
  type,
  disabled,
  margin,
  width,
}) => (
  <StyledButton
    primary={primary}
    onClick={onClick}
    type={type}
    disabled={disabled}
    margin={margin}
    width={width}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
export default Button;
