import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import { css } from 'styled-components';

const Theme = {
  color: {
    primaryColor: '#45D68B',
    secondaryColor: '#FFFFFF',
    bodyColor: '#34373B',
    bodyColorLight: '#D6DCE1',
    borderColor: '#B2B2B2',
    tableBorder: 'rgba(158, 175, 189, 0.8)',
    primaryBackground: '#F9FCFF',
    primaryBackgroundLight: '#D6F5E8',
    primaryBackgroundMedium: 'rgba(236, 239, 242, 0.6)',
    primaryBackgroundDark: '#474747',
    primaryRed: '#E53E3E',
    lightBackground: '#E6E9EC',
    border: '#808B96',
    primaryCardBackground: 'rgba(255, 255, 255, 0.1)',
    textColor: '#282C47',
    secondaryTextColorLight: '#5A6E85',
    secondaryBackground: '#282C47',
    secondaryBackgroundLight: '#5A6E85',
    secondaryCardBackground: '#2C3E50',
    footerBackground: '#2C3E50',
    primaryTitle: '#2C3E50',
    white: '#ffffff',
    lengthColor: '#E6E9EC',
  },
  lineHeights: {
    text: 1.75,
    heading: 1.41,
  },
  fontSizes: {
    small: '12px',
    body: '16px',
    biggerBody: '18px',
    heading: '50px',
    mobileHeading: '35px',
    subHeading: '20px',
    hero: '24px',
    text: '16px',
    h1: '56px',
    h2: '48px',
    h3: '40px',
    h4: '34px',
    h5: '24px',
    h6: '18px',
  },
  fontSize: {
    mobile: {
      body: 16,
      heading: 28,
      subHeading: 24,
    },
    desktop: {
      body: 16,
      heading: 50,
      subHeading: 36,
      small: 12,
    },
  },
  fontWeight: {
    ultraLight: 100,
    light: 200,
    book: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    heavy: 700,
    bold: 800,
    ultraBold: 900,
  },
  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
};

/**
 * Declare Device Sizes
 */
const sizes = {
  SCREEN: 1599,
  DESKTOP: 1366,
  LAPTOP: 1024,
  TABLET_LARGE: 990,
  TABLET: 768,
  MOBILE: 480,
};

const sizeKeys = keys(sizes);

/**
 * Media Query
 * Mobile First Methodology
 */
const widthFrom = reduce(
  sizeKeys,
  (accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {}
);

/**
 * Media Query
 * Desktop First Methodology
 */
const widthUntil = reduce(
  sizeKeys,
  (accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {}
);

export { widthFrom, widthUntil, Theme };
