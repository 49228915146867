import React from 'react';
import styled from 'styled-components';
import { widthFrom } from '@theme/theme';

const StyledExternalLink = styled.a.attrs((props) => ({
  href: props.to,
}))`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.125;
  margin: 0;
  padding: 0;
  word-break: break-word;
  ${widthFrom.TABLET`
    font-size: 18px;
  `}
  &:hover {
    cursor: pointer;
    font-weight: 300;
    transition: all 250ms ease-in-out;
  }
`;
const ImageContainer = styled.span`
  width: 150px;
  display: flex;
  margin: 2vh auto;
  ${widthFrom.TABLET`
    margin: 2vh 0 1.5vh;
  `}
`;

const ExternalLink = ({ children, to, image }) => (
  <StyledExternalLink to={to} target="_blank">
    {image && <ImageContainer>{image}</ImageContainer>}
    {children}
  </StyledExternalLink>
);

export default ExternalLink;
