import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { widthUntil } from '@theme/theme';

const DotsContainer = styled.div`
  position: absolute;
  height: 240px;
  width: 240px;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-left: 168px;
  ${widthUntil.MOBILE`
     margin-left: 0;
  `}
`;

const StyledDotsContainer = ({ children, marginTop }) => (
  <DotsContainer marginTop={marginTop}>{children}</DotsContainer>
);

StyledDotsContainer.propTypes = {
  children: PropTypes.node,
  marginTop: PropTypes.string,
};

export default StyledDotsContainer;
