import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledText = styled.p`
  font-size: ${({ fontSize }) => fontSize || '20px'};
  letter-spacing: 0;
  color: ${({ theme, color }) => theme.color[color] || theme.color.textColor};
  font-weight: ${({ theme, weight }) =>
    theme.fontWeight[weight] || theme.fontWeight.regular};
  line-height: ${({ lineHeight }) => lineHeight || '34px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '1.25vh 0'};
`;
const Text = ({
  weight,
  color,
  children,
  textAlign,
  width,
  margin,
  lineHeight,
  fontSize,
}) => (
  <StyledText
    weight={weight}
    color={color}
    margin={margin}
    textAlign={textAlign}
    width={width}
    lineHeight={lineHeight}
    fontSize={fontSize}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  children: PropTypes.string,
  weight: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  textAlign: PropTypes.string,
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
};
export default Text;
