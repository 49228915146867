import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ onClick, title, className, icon, children }) => (
  <a aria-hidden="true" onClick={() => onClick()} className={className}>
    {icon || null}
    <span>{title}</span>
    {children}
  </a>
);

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Button;
