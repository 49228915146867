import styled from 'styled-components';
import { widthUntil } from '@theme/theme';

const StyledLink = styled.a`
  width: 10em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  visibility: visible;

  span {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    color: ${({ theme }) => theme.color.primaryColor};
  }

  &:hover {
    p {
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
  }

  ${widthUntil.TABLET`
    display: none;
    visibility: hidden;
  `}
`;

export default StyledLink;
