import GlobalStyle from '@theme/GlobalStyle';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Navigation from '@components/navigation';
import { Theme } from '@theme/theme';
import Footer from '@containers/Footer';
import SEO from '@global/seo';

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const PageLayout = (props) => {
  const { children, title, hideHeader, location } = props;
  return (
    <ThemeProvider theme={Theme}>
      <SEO title={title} />
      <GlobalStyle />
      <StyledMain>
        {hideHeader ? null : <Navigation title={title} location={location} />}
        {children}
        {hideHeader ? null : <Footer />}
      </StyledMain>
    </ThemeProvider>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  hideHeader: PropTypes.bool,
  location: PropTypes.object,
};

export default PageLayout;
