import styled from 'styled-components';

const StyledHeader = styled.header`
  width: 100%;
  display: block;
  position: relative;
  background: ${({ theme }) => theme.color.white};
`;

export default StyledHeader;
