import styled from 'styled-components';

const StyledSideColumn = styled.section`
  width: auto;
  height: 80px;
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;

  &.leftAlign {
    left: 0;
  }

  &.rightAlign {
    right: 0;
  }
`;
export default StyledSideColumn;
