import React, { useState, useEffect } from 'react';
import Transition from '@global/utils/Transition';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import * as typeformEmbed from '@typeform/embed';
import { TWButton, TWBadge } from '@styled/components';
import '../assets/styles/tailwind.css';
import LinkOrA from '@components/ui/LinkOrA';
import useNavigationData from '@hooks/use-navigationdata';
import { openPopupWidget } from 'react-calendly';

const Navigation = ({ title, location }) => {
  let typeformLink = 'https://picortex.typeform.com/to/NBpm8D';
  if (location && location.pathname === '/industries/swimming-school') {
    typeformLink = 'https://picortex.typeform.com/to/tz9MhwnS';
  }
  const popup = () => {
    const popup1 = typeformEmbed.makePopup(typeformLink, {
      hideHeaders: true,
      hideFooter: true,
    });
    popup1.open();
  };
  const [isOpen, setIsOpen] = useState(false);
  const [verticalsIsOpen, setVerticalsIsOpen] = useState(false);
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  let isGreen = false;
  if (title === 'Blog' || title === 'Home') {
    isGreen = true;
  }
  let solutionColor;
  if (isGreen) {
    if (!top) {
      solutionColor = 'text-gray-600';
    } else {
      solutionColor = 'text-gray-100';
    }
  } else {
    solutionColor = 'text-gray-600';
  }

  const data = useNavigationData();
  const { links: navLinks, logo, solutions, industries } = data;
  const { footer, products } = solutions.menu;
  const { industries: verticals } = industries.menu;

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top &&
        'bg-white blur shadow-lg'}`}
    >
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div className="lg:w-0 lg:flex-1">
          <span className="inline-flex">
            <Link to="/" className="flex">
              <img className="h-8 w-auto sm:h-10" src={logo} alt="PiCortex" />
            </Link>
          </span>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav className="hidden md:flex space-x-10">
          <div className="relative">
            <button
              type="button"
              className={`${solutionColor} group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="text-xl leading-6 font-medium">
                {solutions.title}
              </span>
              <svg
                className={`${solutionColor} h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <Transition
              show={isOpen}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="z-20 absolute -ml-4 mt-3 transform w-screen max-w-md md:max-w-3xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs overflow-hidden">
                    <div className="z-20 relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                      {products.map((product, key) => (
                        <LinkOrA
                          link={product.link}
                          key={key}
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primaryColor text-white sm:h-12 sm:w-12">
                            {product.image ? (
                              <img
                                className="h-12 w-12"
                                src={product.image}
                                alt={product.title}
                              />
                            ) : (
                              <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d={product.icon}
                                />
                              </svg>
                            )}
                          </div>
                          <div className="space-y-1">
                            <p className="text-lg leading-6 font-medium text-gray-900">
                              {product.title}
                              {product.status === 'coming-soon' ? (
                                <TWBadge
                                  className="inline-flex items-center px-3 ml-2 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"
                                  title="Coming soon"
                                />
                              ) : null}
                            </p>
                            <p className="text-base leading-5 text-gray-500">
                              {product.description}
                            </p>
                          </div>
                        </LinkOrA>
                      ))}
                    </div>
                    <div className=" bg-gray-100 max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                      {footer.actions.map((action, key) => {
                        const isDemo = action.link === '/demo';
                        const pageSettings = {
                          backgroundColor: '#ffffff',
                          hideEventTypeDetails: false,
                          hideLandingPageDetails: false,
                          primaryColor: '#45D68B',
                          textColor: '#4d5055',
                        };
                        return (
                          <div className="flow-root" key={key}>
                            {action.link === '/solutions/b2b' ? (
                              <Link
                                to={action.link}
                                className="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-200 transition ease-in-out duration-150"
                              >
                                <svg
                                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={action.icon}
                                  />
                                </svg>
                                <span>{action.title}</span>
                              </Link>
                            ) : (
                              <TWButton
                                title={action.title}
                                className="-m-3 p-3 space-x-3 flex items-center rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-200 transition ease-in-out duration-150"
                                onClick={
                                  isDemo
                                    ? () =>
                                        openPopupWidget({
                                          url:
                                            'https://calendly.com/picortex/demo',
                                          prefill: null,
                                          pageSettings,
                                          utm: 'PiCortex website',
                                        })
                                    : () => popup()
                                }
                                icon={
                                  <svg
                                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d={action.icon}
                                    />
                                  </svg>
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            {navLinks.map((navLink, key) => (
              <Link
                to={navLink.link}
                className={
                  isGreen
                    ? `text-gray-100 text-xl leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 ml-4 ${!top &&
                        'text-gray-600'}`
                    : `text-gray-600 text-xl leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 ml-4 ${!top &&
                        'text-gray-600'}`
                }
                key={key}
              >
                {navLink.title}
              </Link>
            ))}
            <Link
              type="button"
              className={`${solutionColor} ml-4 group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150`}
              onClick={() => setVerticalsIsOpen(!verticalsIsOpen)}
              to={industries.link}
            >
              <span className="text-xl leading-6 font-medium">
                {industries.title}
              </span>
              <svg
                className={`${solutionColor} h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <Transition
              show={verticalsIsOpen}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="z-20 absolute -ml-4 mt-3 transform w-screen max-w-md md:max-w-3xl lg:ml-0 lg:left-1/2">
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs overflow-hidden">
                    <div className="z-20 relative grid gap-6 bg-gray-100 px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                      {verticals.map((vertical, key) => (
                        <LinkOrA
                          link={vertical.link}
                          key={key}
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-300 transition ease-in-out duration-150"
                        >
                          <div className="space-y-1">
                            <p className="text-lg leading-6 font-medium text-gray-600">
                              {vertical.title}
                            </p>
                          </div>
                        </LinkOrA>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </nav>
        <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
          <span className="inline-flex">
            <a
              className={`whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 text-xl leading-6 font-medium text-gray-100 hover:text-gray-900 focus:outline-none focus:border-primaryColor active:bg-primaryColor transition ease-in-out duration-150 ${!top &&
                'text-gray-600'}`}
              target="_blank"
              rel="noreferrer"
              href="https://id.picortex.com/"
            >
              Log in
            </a>
            <TWButton
              className={`whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 text-lg leading-6 font-medium rounded-md text-white hover:text-primaryColor bg-primaryColor hover:bg-white border-solid border-2 hover:border-primaryColor focus:outline-none focus:border-primaryColor focus:shadow-outline-primaryColor active:bg-primaryColor transition ease-in-out duration-150 ${!top &&
                'border-white'}`}
              title="Sign up"
              onClick={() => {
                if (location && location.pathname === '/industries/retail') {
                  navigate('https://www.piretail.shop/');
                } else {
                  popup();
                }
              }}
            >
              <svg
                className="w-3 h-3 fill-current text-gray-300 flex-shrink-0 ml-2 -mr-1"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </svg>
            </TWButton>
          </span>
        </div>
      </div>
      {/* MBOILE MENU */}
      <Transition
        show={isOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="z-20 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-100">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={logo} alt="PiCortex" />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid grid-cols-1 gap-7">
                    {products.map((product, key) => (
                      <LinkOrA
                        link={product.link}
                        key={key}
                        className="p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-primaryColor text-white">
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d={product.icon}
                            />
                          </svg>
                        </div>
                        <div className="text-base leading-6 font-medium text-gray-900">
                          {product.title}
                          {product.status === 'coming-soon' ? (
                            <TWBadge
                              className="inline-flex items-center px-3 ml-2 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"
                              title="Coming soon"
                            />
                          ) : null}
                        </div>
                      </LinkOrA>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="z-20 py-6 px-5 space-y-6">
                <div className="z-20 grid grid-cols-2 gap-4">
                  {navLinks.map((navLink, key) => (
                    <Link
                      to={navLink.link}
                      className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                      key={key}
                    >
                      {navLink.title}
                    </Link>
                  ))}
                  <Link
                    to={industries.link}
                    className="text-base leading-6 font-medium text-gray-900 hover:text-gray-700 transition ease-in-out duration-150"
                  >
                    {industries.title}
                  </Link>
                </div>
                <div className="z-20 space-y-6">
                  <a
                    className="w-full inline-flex items-center justify-center px-4 py-2 text-base leading-6 font-medium group rounded-md text-gray-600 border-solid border-2 border-gray-600 hover:border hover:border-white hover:bg-white hover:text-gray-600 focus:outline-none transition duration-150 ease-in-out"
                    target="_blank"
                    rel="noreferrer"
                    href="https://id.picortex.com/"
                  >
                    Log in
                  </a>
                  <span className="w-full flex rounded-md shadow-sm">
                    <TWButton
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primaryColor hover:bg-primaryColor focus:outline-none focus:border-primaryColor focus:shadow-outline-primaryColor active:bg-primaryColor transition ease-in-out duration-150"
                      primary
                      title="Sign up"
                      onClick={() => {
                        if (
                          location &&
                          location.pathname === '/industries/retail'
                        ) {
                          navigate('https://www.piretail.shop/');
                        } else {
                          popup();
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  );
};

Navigation.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object,
};
export default Navigation;
