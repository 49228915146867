import React from 'react';
import Image from '@components/image';
import PropTypes from 'prop-types';
import { DotsContainer } from '@styled/components';

const TopDots = ({ image, marginTop }) => (
  <DotsContainer marginTop={marginTop}>
    <Image filename={image.base} alt="dots" />
  </DotsContainer>
);

TopDots.propTypes = {
  image: PropTypes.object.isRequired,
  marginTop: PropTypes.string,
};

export default TopDots;
