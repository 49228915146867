import { StyledContainer, StyledSection } from '@styled/components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';
import useSiteMetadata from '@hooks/use-sitemetadata';

import FooterIcon from '@src/components/ui/FooterIcon';
import LinkOrA from '@components/ui/LinkOrA';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query allFooterSectionJson {
      footerSectionJson {
        social {
          title
          link
          icon
        }
        links {
          title
          subLinks {
            title
            url
            status
          }
        }
      }
    }
  `);
  const { links, social } = data.footerSectionJson;
  const siteMetadata = useSiteMetadata();
  const { description, copyright } = siteMetadata;
  const solutions = links.filter((link) => link.title === 'Solutions');
  const support = links.filter((link) => link.title === 'Support');
  const company = links.filter((link) => link.title === 'Company');
  const legal = links.filter((link) => link.title === 'Legal');
  const contact = links.filter((link) => link.title === 'Contact');

  const footerBlock = (items) => {
    const footerItems = items[0];
    return (
      <div className="mt-12 md:mt-0">
        <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
          {footerItems.title}
        </h4>
        <ul className="mt-4" style={{ listStyleType: 'none' }}>
          {footerItems.subLinks.map((footerItem, key) => (
            <li className={key !== 0 ? 'mt-4' : ''} key={key}>
              <LinkOrA
                link={footerItem.url}
                className="text-base leading-6 text-gray-400 hover:text-gray-500"
                title={footerItem.title}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <div className="bg-footerBackground">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <Link to="/">
              <svg className="h-12 w-12" fill="white" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M6.7.033H6.667V0H3.333A3.333,3.333,0,0,0,0,3.333V16.667A3.333,3.333,0,0,0,3.333,20h0a3.333,3.333,0,0,0,3.333-3.333v-3.3H6.7A6.667,6.667,0,1,0,6.7.033"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <p className="mt-8 text-gray-500 text-base leading-6">
              {description}
            </p>
            <div className="mt-8 flex">
              {social.map((so, key) => (
                <FooterIcon
                  title={so.title}
                  link={so.link}
                  icon={so.icon}
                  key={key}
                />
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {footerBlock(solutions)}
              {footerBlock(company)}
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {footerBlock(support)}
              {footerBlock(contact)}
              {footerBlock(legal)}
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-base leading-6 text-gray-400 md:mt-0 md:order-1">
            {`${new Date().getFullYear()} ${copyright}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
