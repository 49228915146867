import React from 'react';
import styled from 'styled-components';
import { widthUntil } from '@theme/theme';
import PropTypes from 'prop-types';

const StyledSubHeading = styled.h3`
  color: ${({ theme, color }) => theme.color[color] || theme.color.bodyColor};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.semiBold};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  text-shadow: none;
  letter-spacing: 0;
  user-select: none;
  align-self: normal;
  line-height: ${({ lineHeight }) => lineHeight || '34px'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => width || 'unset'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '20px')};
  z-index: ${({ zIndex }) => `${zIndex}` || '1'};
  ${widthUntil.MOBILE`
      width: 100%;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
  `}
`;

const SubHeading = ({
  children,
  tag,
  color,
  fontSize,
  className,
  textAlign,
  fontWeight,
  lineHeight,
  margin,
  padding,
  width,
  zIndex,
}) => (
  <StyledSubHeading
    className={className ? `${className} sub-heading` : 'sub-heading'}
    as={tag}
    color={color}
    fontSize={fontSize}
    textAlign={textAlign}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    margin={margin}
    padding={padding}
    width={width}
    zIndex={zIndex}
  >
    {children}
  </StyledSubHeading>
);

SubHeading.propTypes = {
  children: PropTypes.string,
  zIndex: PropTypes.string,
  tag: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
  textAlign: PropTypes.string,
  width: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.number,
  margin: PropTypes.string,
  padding: PropTypes.string,
};
export default SubHeading;
