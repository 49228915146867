import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledSection = styled.section`
  width: 100%;
  height: ${({ height }) => `${height}px` || 'auto'};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.color[backgroundColor] : 'inherit'};
  ${({ radius }) =>
    radius &&
    `
    border-top-right-radius: ${radius}px;
    border-bottom-right-radius: ${radius}px;
    border-top-left-radius: ${radius}px;
    border-bottom-left-radius: ${radius}px;`};
`;

const Section = ({ children, height }) => (
  <StyledSection height={height}>{children}</StyledSection>
);

Section.propTypes = {
  height: PropTypes.number,
  children: PropTypes.node,
};

export default StyledSection;
