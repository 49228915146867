import styled from 'styled-components';

const StyledHeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: block;
  position: relative;
`;

export default StyledHeaderWrapper;
