import styled from 'styled-components';
import { widthFrom } from '@theme/theme';

const LogoContainer = styled.figure`
  width: 200px;
  display: block;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;

  svg {
    width: auto;
    height: 100%;
  }

  ${widthFrom.TABLET_LARGE`
    font-size: 2rem;
    padding: 0.7em;
  `}
`;
export default LogoContainer;
