import { useStaticQuery, graphql } from 'gatsby';

export const useNavigationData = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      navigationSectionJson {
        links {
          icon
          link
          title
        }
        logo
        industries {
          icon
          link
          title
          menu {
            industries {
              link
              title
            }
          }
        }
        solutions {
          icon
          link
          title
          menu {
            products {
              description
              icon
              image
              link
              status
              title
            }
            footer {
              title
              description
              actions {
                icon
                link
                title
              }
            }
          }
        }
      }
    }
  `);
  return data.navigationSectionJson;
};

export default useNavigationData;
