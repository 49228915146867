import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ title, className }) => (
  <span className={className}>{title}</span>
);

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Badge;
