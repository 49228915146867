import React from 'react';
import styled from 'styled-components';
import { widthFrom } from '@theme/theme';

const StyledExternalLink = styled.a.attrs((props) => ({
  href: props.to,
}))`
  background: ${({ theme }) => theme.color.secondaryColor};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.125;
  margin: 1.5vh 0;
  padding: 12px 40px;
  color: ${({ theme }) => theme.color.white};
  max-width: 225px;
  display: block;
  text-align: center;
  border-radius: 50px;
  box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.15);
  ${widthFrom.TABLET`
    font-size: 18px;
  `}
  &:hover {
    cursor: pointer;
    font-weight: 300;
    transition: all 250ms ease-in-out;
    box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.35);
  }
`;

const ExternalLink = ({ children, to }) => (
  <StyledExternalLink to={to} target="_blank">
    {children}
  </StyledExternalLink>
);

export default ExternalLink;
