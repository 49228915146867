import styled from 'styled-components';
import { widthUntil } from '@theme/theme';

const CheckItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  width: 50%;
  margin-bottom: 24px;
  ${widthUntil.MOBILE`
      width: 100%;
  `}
`;
// align-items: center;

export default CheckItemContainer;
