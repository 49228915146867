import React from 'react';
import styled from 'styled-components';
import { widthFrom, widthUntil } from '@theme/theme';
import PropTypes from 'prop-types';

const StyledHeading = styled.h1`
  color: ${({ theme, color }) =>
    theme.color[color] || theme.color.primaryTitle};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '50px')};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight || theme.fontWeight.bold};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  text-shadow: none;
  letter-spacing: 0;
  user-select: none;
  line-height: ${({ lineHeight }) => lineHeight || '60px'};
  margin: ${({ margin }) => margin || '0 0 20px 0'};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => `${width}` || 'unset'};
  z-index: ${({ zIndex }) => `${zIndex}` || '1'};
  ${widthFrom.TABLET`
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '50px')};
  `}
  ${widthUntil.MOBILE`
      font-size: 42px;
      line-height: 46px;
      text-align: center;
  `}
`;
// line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '60px')};

const Heading = ({
  children,
  tag,
  color,
  fontSize,
  className,
  textAlign,
  fontWeight,
  lineHeight,
  margin,
  width,
  padding,
  zIndex,
}) => (
  <StyledHeading
    className={className ? `${className} heading` : 'heading'}
    as={tag}
    color={color}
    fontSize={fontSize}
    textAlign={textAlign}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    margin={margin}
    width={width}
    padding={padding}
    zIndex={zIndex}
  >
    {children}
  </StyledHeading>
);

Heading.propTypes = {
  children: PropTypes.string,
  zIndex: PropTypes.string,
  tag: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  className: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.number,
  margin: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
};

export default Heading;
