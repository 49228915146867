import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Image = ({ filename, alt }) => (
  <StaticQuery
    query={graphql`
      query QueryImages {
        images: allImageSharp {
          nodes {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.nodes.find((item) =>
        item.fluid.originalName.includes(filename)
      );

      if (!image) {
        return null;
      }

      return <Img alt={alt} fluid={image.fluid} />;
    }}
  />
);

Image.propTypes = {
  alt: PropTypes.string,
  filename: PropTypes.string,
};

export default Image;
