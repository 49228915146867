import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              linkedin
              instagram
              facebook
            }
            contact {
              address
              email
              website
              addressInfos {
                id
                state
                address
                email
                phone
              }
            }
            title
            description
            author
            copyright
          }
        }
      }
    `
  );
  return data.site.siteMetadata;
};

export default useSiteMetadata;
